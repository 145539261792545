/* LANDING.JS */

/* MISCELLANEOUS */
* {
    font-family: 'Outfit', sans-serif;
}

.center-AI {
    align-items: center;
}

.section-title {
    background: 
    linear-gradient(to bottom, rgba(12, 12, 12, 0.7), rgba(70, 78, 48, 0.8)),
    url('./assets/foliage.jpg');
    /* linear-gradient(to bottom, rgba(239, 250, 208, 0.52), rgba(224, 240, 186, 0.73)); */
    background-size: cover;
    background-position: center;
    font-size: 1.2em;
}

.carousel-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.yellowgreen {
    background-color: #86d712;
}

.goldenrod {
    background-color: #fbbf24;
}


/* NAVBAR */
.brand-name {
    margin-left: 8px;
}


/* FRUIT'S INFORMATION */
.fruit-body {
    background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    url('./assets/one-durian.jpg');
    background-size: cover;
    background-position: center;
}

.closer-LH {
    line-height: 0.98em;
}

.FI-fruit-name {
    font-size: 2.66em;
}

#FI-text-beside-carousel {
    padding-left: 12px;
}

.FI-details {
    font-size: 0.75em;
}

@media only screen and (max-width: 576px) {
    .FI-fruit-name {
      font-size: 2.6em;
    }
}

@media only screen and (max-width: 564px) {
    .FI-fruit-name {
      font-size: 2.5em;
    }
}

@media only screen and (max-width: 544px) {
    .FI-fruit-name {
      font-size: 2.4em;
    }
}

@media only screen and (max-width: 526px) {
    .FI-fruit-name {
      font-size: 2.3em;
    }
}

@media only screen and (max-width: 506px) {
    .FI-fruit-name {
      font-size: 2.2em;
    }
}

@media only screen and (max-width: 486px) {
    .FI-fruit-name {
      font-size: 2.1em;
    }
}

@media only screen and (max-width: 468px) {
    .FI-fruit-name {
      font-size: 2em;
    }
}

@media only screen and (max-width: 448px) {
    .FI-fruit-name {
      font-size: 1.9em;
    }
}

@media only screen and (max-width: 430px) {
    .FI-fruit-name {
      font-size: 1.8em;
    }
}

@media only screen and (max-width: 410px) {
    .FI-fruit-name {
      font-size: 1.7em;
    }
}

@media only screen and (max-width: 390px) {
    .FI-fruit-name {
      font-size: 1.6em;
    }
}

@media only screen and (max-width: 372px) {
    .FI-fruit-name {
      font-size: 1.5em;
    }
}


/* FARM STORY */
#FS-farm-name {
    font-size: 1.6em;
}

.farm-body {
    background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9)),
    url('./assets/durian-hand.jpg');
    background-size: cover;
    background-position: center;
}


/* LOCATION */
.location-body {
    background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9)),
    url('./assets/forest.jpg');
    background-size: cover;
    background-position: center;
}


/* FEEDBACK.JS */
.feedback-bg-color {
    background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    url('./assets/durians.jpg');
    background-size: cover;
    background-position: center;
}

#upload-svg {
    margin: 0 1em 0 0.6em;
}

#add-photos-btn {
    margin: 0.7em 0.7em 0.7em 0;
}

@media only screen and (max-width: 580px) {
    #additional-feedback {
        margin-right: 0.63em;
    }
}

@media only screen and (max-width: 400px) {
    #upload-svg {
        margin: 0 0.5em 0 1em;
    }
}

@media only screen and (max-width: 396px) {
    #upload-svg {
        margin: 0 0.4em 0 0.6em;
    }
}

@media only screen and (max-width: 380px) {
    #upload-svg {
        margin: 0 0.2em 0 0.4em;
    }   

    #add-photos-btn {
        margin: 0.7em 0.4em 0.7em 0;
    }
}